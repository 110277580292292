import firebase from "gatsby-plugin-firebase"

import { isBrowser } from './browser'


export const firebaseFunctions = isBrowser() ? firebase.functions() : null
if (firebaseFunctions && process.env.GATSBY_FIREBASE_FUNCTIONS_EMULATOR === 'ON') {
  firebaseFunctions.useFunctionsEmulator('http://localhost:5001')
}

export const db = isBrowser() ? firebase.firestore() : null

if (db) {
  db.enablePersistence()
}
