import { firebaseFunctions } from './firebase'
import { db } from './firebase'


export const claimSchool = (schoolId) => {
  const firebaseCall = firebaseFunctions.httpsCallable('schools-claim')
  return firebaseCall({schoolId: schoolId})
  .then(res => {
    return res
  })
}

export const getSchools = ({field, operator, value}) => {
  if (!db) {
    return null;
  }
  
  const collectionRef = db.collection('schools').where(field, operator, value)
  
  return collectionRef
  .get()
  .then(schoolsSnapshot => {
    const schools = []
    schoolsSnapshot.forEach(doc => { // XXX: Use a map instead!
      schools.push({ id: doc.id, data: doc.data() })
    })
    return schools;
  })
}


export const getSchoolsForUser = (user) => {
  if (!db) {
    return null;
  }
  
  return db.collection('schools')
  .where('owner', '==', user.uid)
  .get()
  .then(schoolsSnapshot => {
    const schools = []
    schoolsSnapshot.forEach(doc => {
      schools.push({ id: doc.id, data: doc.data() })
    })
    return schools;
  })
}


export const relinquishOwnershipOfAllSchools = () => {
  const firebaseCall = firebaseFunctions.httpsCallable('schools-relinquishOwnership')
  return firebaseCall()
}


export const updateSchool = (schoolId, partialSchool) => {
  return db.collection('schools').doc(schoolId).update(partialSchool)
}
